<template>
  <div class="all">
    <Nav></Nav>
    <div class="about">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>公告>>详情
      </div>
      <div class="announce-list">
        <div class="announce-con">
          <div class="annoce-title">
            <div class="title">{{ activityInfo.title }}</div>
            <div class="time">{{ activityInfo.createTime }}</div>
          </div>
          <div class="annoce-nav">
            <img :src="imgurl" alt="" />
            <div class="text">
              <div class="detail-text rich-text-preview" v-html="activityInfo.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
// @ is an alias to /src
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
export default {
  name: 'Home',
  components: { Nav, Bottom },
  data: function () {
    return {
      activityInfo: {}, // 公告的类型
      imgurl: '',
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('78', this.$route.query)

      RD.notice()
        .id(this.$route.query.id)
        .one()
        .then((data) => {
          thiz.imgurl = RD.notice().id(data.id).noticeImage_URL()
          console.log('jk', thiz.imgurl)
          thiz.activityInfo = data
        })
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  height: auto;
  background: #f5f5f5;
}
.about {
  .catalogtitle {
    height: 40px;
    text-align: left;
    width: 1200px;
    margin: 0 auto;
    font-size: 1.2rem;
    padding-top: 15px;
    display: flex;
    border-bottom: 1px solid #bfbfbf;
    .icon {
      width: 20px;
      height: 20px;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 10px;
    }
  }
  .announce-list {
    width: 1200px;
    margin: 0 auto;
    .announce-con {
      width: 1000px;
      margin: 0 auto;
      background: #fff;
      .annoce-title {
        font-size: 2.5rem;
        border-bottom: 2px solid #ccc;
        height: 95px;
        .title {
          width: 900px;
          margin: 0 auto;
          padding-top: 20px;
        }
        .time {
          width: 900px;
          margin: 10px auto;
          font-size: 1.5rem;
          text-align: left;
          height: 20px;
          line-height: 20px;
        }
      }
      .annoce-nav {
        width: 900px;
        margin: 20px auto;
        img {
          width: 80rem;
          margin: 0 auto;
          object-fit: cover;
        }
        .text {
          margin-top: 20px;
          .detail-text {
            // font-size: 1.3rem;
            text-align: left;
            // letter-spacing: 2px;
            // white-space: pre-wrap;
            // word-spacing: 1em;
            // letter-spacing: 2px;
          }
          .rich-text-preview {
            // max-height: 60vh;
            overflow-y: auto;
            ::v-deep p {
              text-align: left;
              line-height: 40px;
              font-size: 18px;
              word-spacing: 0.25em;
            }
            ::v-deep pre {
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }
}
</style>
